<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form :form="pageForm" @submit="pageSubmit">
      <a-row style="margin-bottom: 20px">
        <a-col span="24">
          <a-form-item :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }">
            <a-radio-group
              name="type"
              v-decorator="['type', pageFormRules.type]"
              @change="handleTypeChange"
            >
              <!--<a-radio value="1">总价加收收入</a-radio>
              <a-radio value="2">自定义收入加收字段</a-radio>-->

              <a-radio value="3">自定义每公斤加收单价</a-radio>
              <a-radio value="4">自定义分段收入</a-radio>
              <a-radio value="5">按流向加收</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>

      <!--<div v-show="fieldType == '1'">
        <a-row>
          <a-col :xl="8" :lg="24" :md="24" :sm="24">
            <a-form-item label="成本总价" :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }">
              <a-input-number
                name="totalCost"
                :min="0"
                :max="99.99"
                :step="0.01"
                v-decorator="['totalCost', pageFormRules.totalCost]"
              />
              <a-button disabled class="percentage"> %</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </div>-->

      <!--<div v-show="fieldType == '2'">
        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="一级运费" :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }">
              <a-input-number
                name="firstTransportFee"
                :min="0"
                :max="99.99"
                :step="0.01"
                v-decorator="['firstTransportFee', pageFormRules.firstTransportFee]"
              />
              <a-button disabled class="percentage"> %</a-button>
            </a-form-item>
          </a-col>

          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="二级运费" :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }">
              <a-input-number
                name="secondTransportFee"
                :min="0"
                :max="99.99"
                :step="0.01"
                v-decorator="['secondTransportFee', pageFormRules.secondTransportFee]"
              />
              <a-button disabled class="percentage"> %</a-button>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="派件费" :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }">
              <a-input-number
                name="dispatchFee"
                :min="0"
                :max="99.99"
                :step="0.01"
                v-decorator="['dispatchFee', pageFormRules.dispatchFee]"
              />
              <a-button disabled class="percentage"> %</a-button>
            </a-form-item>
          </a-col>

          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="提货费" :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }">
              <a-input-number
                name="pickupFee"
                :min="0"
                :max="99.99"
                :step="0.01"
                v-decorator="['pickupFee', pageFormRules.pickupFee]"
              />
              <a-button disabled class="percentage"> %</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </div>-->

      <div v-show="fieldType == '3'">
        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="10KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range1ContinuedWeightPrice"
                :min="0"
                :step="0.01"
                v-decorator="['range1ContinuedWeightPrice', pageFormRules.range1ContinuedWeightPrice]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>

          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="100KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range2ContinuedWeightPrice"
                :min="0"
                :step="0.01"
                v-decorator="['range2ContinuedWeightPrice', pageFormRules.range2ContinuedWeightPrice]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="300KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range3ContinuedWeightPrice"
                :min="0"
                :step="0.01"
                v-decorator="['range3ContinuedWeightPrice', pageFormRules.range3ContinuedWeightPrice]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>

          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="500KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range4ContinuedWeightPrice"
                :min="0"
                :step="0.01"
                v-decorator="['range4ContinuedWeightPrice', pageFormRules.range4ContinuedWeightPrice]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="1200KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range5ContinuedWeightPrice"
                :min="0"
                :step="0.01"
                v-decorator="['range5ContinuedWeightPrice', pageFormRules.range5ContinuedWeightPrice]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>

        </a-row>

      </div>

      <div v-show="fieldType == '4'">
       <a-row style="font-size:16px;font-weight:bold;margin-bottom:15px;">
          <a-col :xl="8" :lg="12" :md="24" :sm="24" >
              一级运费：
          </a-col>
       </a-row>   
        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="10KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range1FirstWeightPriceFirstTransport"
                :min="0"
                :step="0.01"
                v-decorator="['range1FirstWeightPriceFirstTransport', pageFormRules.range1FirstWeightPriceFirstTransport]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>

          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="100KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range2FirstWeightPriceFirstTransport"
                :min="0"
                :step="0.01"
                v-decorator="['range2FirstWeightPriceFirstTransport', pageFormRules.range2FirstWeightPriceFirstTransport]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="300KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range3FirstWeightPriceFirstTransport"
                :min="0"
                :step="0.01"
                v-decorator="['range3FirstWeightPriceFirstTransport', pageFormRules.range3FirstWeightPriceFirstTransport]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>

          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="500KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range4FirstWeightPriceFirstTransport"
                :min="0"
                :step="0.01"
                v-decorator="['range4FirstWeightPriceFirstTransport', pageFormRules.range4FirstWeightPriceFirstTransport]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="1200KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range5FirstWeightPriceFirstTransport"
                :min="0"
                :step="0.01"
                v-decorator="['range5FirstWeightPriceFirstTransport', pageFormRules.range5FirstWeightPriceFirstTransport]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>

        </a-row>


       <a-row style="font-size:16px;font-weight:bold;margin-bottom:15px;">
          <a-col :xl="8" :lg="12" :md="24" :sm="24" >
              二级运费：
          </a-col>
       </a-row>   
        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="每公斤加收" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="basicsSecondTransport"
                :min="0"
                :step="0.01"
                v-decorator="['basicsSecondTransport', pageFormRules.basicsSecondTransport]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>        
        </a-row>

      <a-row style="font-size:16px;font-weight:bold;margin-bottom:15px;">
          <a-col :xl="8" :lg="12" :md="24" :sm="24" >
              派费：
       </a-col>
       </a-row>   
       
        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="10KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range1FirstWeightPriceDispatchFee"
                :min="0"
                :step="0.01"
                v-decorator="['range1FirstWeightPriceDispatchFee', pageFormRules.range1FirstWeightPriceDispatchFee]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>

          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="100KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range2FirstWeightPriceDispatchFee"
                :min="0"
                :step="0.01"
                v-decorator="['range2FirstWeightPriceDispatchFee', pageFormRules.range2FirstWeightPriceDispatchFee]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="300KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range3FirstWeightPriceDispatchFee"
                :min="0"
                :step="0.01"
                v-decorator="['range3FirstWeightPriceDispatchFee', pageFormRules.range3FirstWeightPriceDispatchFee]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>

          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="500KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range4FirstWeightPriceDispatchFee"
                :min="0"
                :step="0.01"
                v-decorator="['range4FirstWeightPriceDispatchFee', pageFormRules.range4FirstWeightPriceDispatchFee]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="1200KG档加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="range5FirstWeightPriceDispatchFee"
                :min="0"
                :step="0.01"
                v-decorator="['range5FirstWeightPriceDispatchFee', pageFormRules.range5FirstWeightPriceDispatchFee]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>

        </a-row>

      <a-row style="font-size:16px;font-weight:bold;margin-bottom:15px;">
          <a-col :xl="8" :lg="12" :md="24" :sm="24" >
              提货费
       </a-col>
       </a-row>   
        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="每公斤加收" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="basicsPickupFee"
                :min="0"
                :step="0.01"
                v-decorator="['basicsPickupFee', pageFormRules.basicsPickupFee]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>        
        </a-row>


       <a-row style="font-size:16px;font-weight:bold;margin-bottom:15px;">
          <a-col :xl="8" :lg="12" :md="24" :sm="24" >
              交仓费：
         </a-col>
       </a-row>   
       
        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="装卸费加收" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="handlingFeeWarehouseIn"
                :min="0"
                :step="0.01"
                v-decorator="['handlingFeeWarehouseIn', pageFormRules.handlingFeeWarehouseIn]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>

          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="分拣费每件加收" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="sortingFeeWarehouseIn"
                :min="0"
                :step="0.01"
                v-decorator="['sortingFeeWarehouseIn', pageFormRules.sortingFeeWarehouseIn]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="托盘费每个加收" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="palletFeeWarehouseIn"
                :min="0"
                :step="0.01"
                v-decorator="['palletFeeWarehouseIn', pageFormRules.palletFeeWarehouseIn]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        
        </a-row>

      </div>


       <div v-show="fieldType == '5'">
        <a-row style="font-size:14px;margin-bottom:5px;line-height:1;color: rgba(0, 0, 0, 0.85);">
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            华北地区：北京、天津、河北、山西、内蒙古
          </a-col>         
        </a-row>
        <a-row>
           <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="huabei"
                :min="0"
                :step="0.01"
                v-decorator="['huabei', pageFormRules.huabei]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row style="font-size:14px;margin-bottom:5px;line-height:1;color: rgba(0, 0, 0, 0.85);">
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            华东地区：上海，江苏，浙江，山东，安徽
          </a-col>         
        </a-row>
        <a-row>
           <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="huadong"
                :min="0"
                :step="0.01"
                v-decorator="['huadong', pageFormRules.huadong]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        </a-row>


        <a-row style="font-size:14px;margin-bottom:5px;line-height:1;color: rgba(0, 0, 0, 0.85);">
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            东北地区：辽宁，吉林，黑龙江
          </a-col>         
        </a-row>
        <a-row>
           <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="dongbei"
                :min="0"
                :step="0.01"
                v-decorator="['dongbei', pageFormRules.dongbei]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row style="font-size:14px;margin-bottom:5px;line-height:1;color: rgba(0, 0, 0, 0.85);">
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            华中地区：湖北，湖南，河南，江西
          </a-col>         
        </a-row>
        <a-row>
           <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="huazhong"
                :min="0"
                :step="0.01"
                v-decorator="['huazhong', pageFormRules.huazhong]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        </a-row>


         <a-row style="font-size:14px;margin-bottom:5px;line-height:1;color: rgba(0, 0, 0, 0.85);">
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            华南地区：广东，广西，海南，福建
          </a-col>         
        </a-row>
        <a-row>
           <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="huanan"
                :min="0"
                :step="0.01"
                v-decorator="['huanan', pageFormRules.huanan]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        </a-row>


        <a-row style="font-size:14px;margin-bottom:5px;line-height:1;color: rgba(0, 0, 0, 0.85);">
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            西南地区：四川，重庆，贵州，云南，西藏
          </a-col>         
        </a-row>
        <a-row>
           <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="xinan"
                :min="0"
                :step="0.01"
                v-decorator="['xinan', pageFormRules.xinan]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row style="font-size:14px;margin-bottom:5px;line-height:1;color: rgba(0, 0, 0, 0.85);">
          <a-col :xl="8" :lg="12" :md="24" :sm="24">
            西北地区：陕西，甘肃，新疆，青海，宁夏
          </a-col>         
        </a-row>
        <a-row>
           <a-col :xl="8" :lg="12" :md="24" :sm="24">
            <a-form-item label="加收单价" :labelCol="{ lg: { span: 10 }, sm: { span: 12 } }">
              <a-input-number
                name="xibei"
                :min="0"
                :step="0.01"
                v-decorator="['xibei', pageFormRules.xibei]"
              />
              <a-button disabled class="percentage"> 元</a-button>
            </a-form-item>
          </a-col>
        </a-row>


      </div>






      <a-form-item :wrapperCol="{ span: 12 }">
        <a-input name="id" type="hidden" style="display: none" v-decorator="['id', pageFormRules.id]" />
        <div style="text-align: left;width:100%">
          <a-button type="primary" htmlType="submit">保存</a-button>
        </div>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { profitInfo, profitInsert, profitUpdate } from '@/api/quotation'

const pageFormRules = {
  id: { initialValue: null },
  type: { initialValue: '3' },

  /*totalCost: { rules: [{ required: true, message: '请输入成本总价加收比例' }] },
  firstTransportFee: { rules: [{ required: true, message: '请输入一级运费加收比例' }] },
  secondTransportFee: { rules: [{ required: true, message: '请输入二级运费加收比例' }] },
  dispatchFee: { rules: [{ required: true, message: '请输入派件费加收比例' }] },
  pickupFee: { rules: [{ required: true, message: '请输入提货费加收比例' }] },*/

  range1ContinuedWeightPrice: { rules: [{ required: true, message: '请输入10KG档加收单价' }] },
  range2ContinuedWeightPrice: { rules: [{ required: true, message: '请输入100KG档加收单价' }] },
  range3ContinuedWeightPrice: { rules: [{ required: true, message: '请输入300KG档加收单价' }] },
  range4ContinuedWeightPrice: { rules: [{ required: true, message: '请输入500KG档加收单价' }] },
  range5ContinuedWeightPrice: { rules: [{ required: true, message: '请输入1200KG档加收单价' }] },

  range1FirstWeightPriceFirstTransport: { rules: [{ required: true, message: '请输入10KG档加收单价' }] },
  range2FirstWeightPriceFirstTransport: { rules: [{ required: true, message: '请输入100KG档加收单价' }] },
  range3FirstWeightPriceFirstTransport: { rules: [{ required: true, message: '请输入300KG档加收单价' }] },
  range4FirstWeightPriceFirstTransport: { rules: [{ required: true, message: '请输入500KG档加收单价' }] },
  range5FirstWeightPriceFirstTransport: { rules: [{ required: true, message: '请输入1200KG档加收单价' }] },
   

  basicsSecondTransport: { rules: [{ required: true, message: '请输入每公斤加收' }] },

  range1FirstWeightPriceDispatchFee: { rules: [{ required: true, message: '请输入10KG档加收单价' }] },
  range2FirstWeightPriceDispatchFee: { rules: [{ required: true, message: '请输入100KG档加收单价' }] },
  range3FirstWeightPriceDispatchFee: { rules: [{ required: true, message: '请输入300KG档加收单价' }] },
  range4FirstWeightPriceDispatchFee: { rules: [{ required: true, message: '请输入500KG档加收单价' }] },
  range5FirstWeightPriceDispatchFee: { rules: [{ required: true, message: '请输入1200KG档加收单价' }] },

  basicsPickupFee: { rules: [{ required: true, message: '请输入每公斤加收' }] },
  handlingFeeWarehouseIn: { rules: [{ required: true, message: '请输入装卸费加收' }] },
  sortingFeeWarehouseIn: { rules: [{ required: true, message: '请输入分拣费每件加收' }] },
  palletFeeWarehouseIn: { rules: [{ required: true, message: '请输入托盘费每个加收' }] },

  huabei: { rules: [{ required: true, message: '加收单价' }] },
  huadong: { rules: [{ required: true, message: '加收单价' }] },
  dongbei: { rules: [{ required: true, message: '加收单价' }] },
  huazhong: { rules: [{ required: true, message: '加收单价' }] },
  huanan: { rules: [{ required: true, message: '加收单价' }] },
  xinan: { rules: [{ required: true, message: '加收单价' }] },
  xibei: { rules: [{ required: true, message: '加收单价' }] },  

  
}
const pageFormField = { 
  type3: ['id', 'type', 'range1ContinuedWeightPrice', 'range2ContinuedWeightPrice', 'range3ContinuedWeightPrice', 'range4ContinuedWeightPrice', 'range5ContinuedWeightPrice'],
  type4: ['id', 'type', 'range1FirstWeightPriceFirstTransport', 'range2FirstWeightPriceFirstTransport', 'range3FirstWeightPriceFirstTransport', 'range4FirstWeightPriceFirstTransport','range5FirstWeightPriceFirstTransport', 
  'basicsSecondTransport',
  'range1FirstWeightPriceDispatchFee','range2FirstWeightPriceDispatchFee','range3FirstWeightPriceDispatchFee','range4FirstWeightPriceDispatchFee','range5FirstWeightPriceDispatchFee',
  'basicsPickupFee','handlingFeeWarehouseIn','sortingFeeWarehouseIn','palletFeeWarehouseIn'
  ],
  type5: ['id', 'type', 'huabei', 'huadong', 'dongbei', 'huazhong','huanan', 'xinan','xibei']
}

export default {
  name: 'BaseForm',
  data() {
    return {
      pageForm: this.$form.createForm(this),
      pageFormRules: pageFormRules,
      fieldType: '3',
    }
  },
  mounted() {
    this.pageLoad()
  },
  methods: {
    pageLoad() {
      let that = this
      profitInfo({})
        .then((res) => {
          if (0 == res.code) {
            const record = res.result
            if (typeof(record) != 'object') {
              return;
            }
            that.$nextTick(() => {             
              that.pageForm.setFieldsValue({
                id: record.id,
                type: String(record.type),
                range1ContinuedWeightPrice: record.range1ContinuedWeightPrice,
                range2ContinuedWeightPrice: record.range2ContinuedWeightPrice,
                range3ContinuedWeightPrice: record.range3ContinuedWeightPrice,
                range4ContinuedWeightPrice: record.range4ContinuedWeightPrice,
                range5ContinuedWeightPrice: record.range5ContinuedWeightPrice,
                range1FirstWeightPriceFirstTransport:record.firstTransportFeeExtMap.range1FirstWeightPrice,
                range2FirstWeightPriceFirstTransport:record.firstTransportFeeExtMap.range2FirstWeightPrice,
                range3FirstWeightPriceFirstTransport:record.firstTransportFeeExtMap.range3FirstWeightPrice,
                range4FirstWeightPriceFirstTransport:record.firstTransportFeeExtMap.range4FirstWeightPrice,
                range5FirstWeightPriceFirstTransport:record.firstTransportFeeExtMap.range5FirstWeightPrice,
                basicsSecondTransport:record.secondTransportFeeExtMap.basics,
                range1FirstWeightPriceDispatchFee:record.dispatchFeeExtMap.range1FirstWeightPrice,
                range2FirstWeightPriceDispatchFee:record.dispatchFeeExtMap.range2FirstWeightPrice,
                range3FirstWeightPriceDispatchFee:record.dispatchFeeExtMap.range3FirstWeightPrice,
                range4FirstWeightPriceDispatchFee:record.dispatchFeeExtMap.range4FirstWeightPrice,
                range5FirstWeightPriceDispatchFee:record.dispatchFeeExtMap.range5FirstWeightPrice,
                basicsPickupFee:record.pickupFeeExtMap.basics,
                handlingFeeWarehouseIn:record.warehouseInFeeExtMap.handlingFee,
                sortingFeeWarehouseIn:record.warehouseInFeeExtMap.sortingFee,
                palletFeeWarehouseIn:record.warehouseInFeeExtMap.palletFee,
                huabei:record.flowDirectionExtMap.huabei,
                huadong:record.flowDirectionExtMap.huadong,
                dongbei:record.flowDirectionExtMap.dongbei,
                huazhong:record.flowDirectionExtMap.huazhong,
                huanan:record.flowDirectionExtMap.huanan,
                xinan:record.flowDirectionExtMap.xinan,
                xibei:record.flowDirectionExtMap.xibei,
                
              })
              that.fieldType = String(record.type)
            })
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('数据加载失败，请稍后重试')
        })
    },
    pageSubmit(e) {
      e.preventDefault()
      let that = this
      const typeField = 'type' + this.fieldType
      this.pageForm.validateFields(pageFormField[typeField], (err, values) => {
        if (!err) {
          values.firstTransportFeeExtMap = {range1FirstWeightPrice:values.range1FirstWeightPriceFirstTransport,range2FirstWeightPrice:values.range2FirstWeightPriceFirstTransport,range3FirstWeightPrice:values.range3FirstWeightPriceFirstTransport,range4FirstWeightPrice:values.range4FirstWeightPriceFirstTransport,range5FirstWeightPrice:values.range5FirstWeightPriceFirstTransport}         
          values.secondTransportFeeExtMap = {basics:values.basicsSecondTransport}
          values.dispatchFeeExtMap = {range1FirstWeightPrice:values.range1FirstWeightPriceDispatchFee,range2FirstWeightPrice:values.range2FirstWeightPriceDispatchFee,range3FirstWeightPrice:values.range3FirstWeightPriceDispatchFee,range4FirstWeightPrice:values.range4FirstWeightPriceDispatchFee,range5FirstWeightPrice:values.range5FirstWeightPriceDispatchFee}                    
          values.pickupFeeExtMap = {basics:values.basicsPickupFee}          
          values.warehouseInFeeExtMap = {handlingFee:values.handlingFeeWarehouseIn,sortingFee:values.sortingFeeWarehouseIn,palletFee:values.palletFeeWarehouseIn}
          values.flowDirectionExtMap = {huabei:values.huabei,huadong:values.huadong,dongbei:values.dongbei,huazhong:values.huazhong,huanan:values.huanan,xinan:values.xinan,xibei:values.xibei}
          
          //删除冗余字段
          delete values.range1FirstWeightPriceFirstTransport
          delete values.range2FirstWeightPriceFirstTransport
          delete values.range3FirstWeightPriceFirstTransport
          delete values.range4FirstWeightPriceFirstTransport
          delete values.range5FirstWeightPriceFirstTransport
          delete values.basicsSecondTransport
          delete values.range1FirstWeightPriceDispatchFee
          delete values.range2FirstWeightPriceDispatchFee
          delete values.range3FirstWeightPriceDispatchFee
          delete values.range4FirstWeightPriceDispatchFee
          delete values.range5FirstWeightPriceDispatchFee
          delete values.basicsPickupFee
          delete values.handlingFeeWarehouseIn
          delete values.sortingFeeWarehouseIn
          delete values.palletFeeWarehouseIn

          delete values.huabei
          delete values.huadong
          delete values.dongbei
          delete values.huazhong
          delete values.xinan
          delete values.xibei
         
          if (values.id) {
            that.handleUpdate(values)
          } else {
            that.handleInsert(values)
          }
        }
      })
    },
    handleInsert(parameter) {
      let that = this
      profitInsert(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$success({ title: '保存成功' })
            that.pageLoad()
          } else {
            this.$error({ title: '保存失败' })
          }
        })
        .catch((e) => {
          console.info(e)
          this.$error({ title: '保存失败，请稍后重试' })
        })
    },
    handleUpdate(parameter) {
      let that = this
      profitUpdate(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$success({ title: '保存成功' })
            that.pageLoad()
          } else {
            this.$error({ title: '保存失败' })
          }
        })
        .catch((e) => {
          console.info(e)
          this.$error({ title: '保存失败，请稍后重试' })
        })
    },
    handleTypeChange(e) {
      this.fieldType = e.target.value
    },
  },
}
</script>
<style lang="less" scoped>
  .ant-form{
   /deep/ .ant-form-item-children{
      display: flex;
      .ant-input-number{
        border-radius: 5px 0 0 5px;
      }
      .percentage{
        border-radius:0 5px 5px 0;
        border-left: 0;
      }
    }
  }
  /deep/ .ant-form-item-label{
  text-align: left !important;
  width: 140px;
}
.ant-form-item{
  display: flex;
  align-items: center;
}
</style>